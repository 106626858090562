import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('admin/merchant/listMerchant', data)
}
//修改
export function getEdit(data) {
    return post('admin/merchant/editMerchant', data)
}
//添加
export function getAdd(data) {
    return post('admin/merchant/addMerchant', data)
}
//设置状态
export function getState(data) {
    return post('admin/merchant/upMerchant', data)
}
//删除
export function getDel(data) {
    return post('admin/merchant/delMerchant', data)
}
//下拉
export function getOption(data) {
    return post('admin/merchant/section', data)
}

